import themeState, { ThemeType } from "../utils/theme.ts"
import {
  MdOutlineDarkMode as DarkThemeIcon,
  MdOutlineDesktopWindows as SystemThemeIcon,
  MdOutlineLightMode as LightThemeIcon,
} from "react-icons/md"

export default function ThemeSwitcher() {
  const toggleTheme = () => {
    // calculate new theme
    const newTheme = themeState.value.dark
      ? { dark: false, type: ThemeType.Light }
      : { dark: true, type: ThemeType.Dark }

    // change signal to new theme
    themeState.value = newTheme

    // persistence setting
    document.documentElement.classList[newTheme.dark ? "add" : "remove"]("dark")
    localStorage.theme = newTheme.dark ? "dark" : "light"
  }

  return (
    <>
      <span>{themeState.value.dark}</span>
      <button
        onClick={toggleTheme}
        title="点击切换主题"
        class="focus:outline-none rounded-md p-0.5 text-gray-600 hover:bg-gray-400 dark:text-gray-400 dark:hover:bg-gray-600"
      >
        {themeState.value.type === ThemeType.Dark
          ? <DarkThemeIcon class="w-6 h-6" />
          : (themeState.value.type === ThemeType.Light
            ? <LightThemeIcon class="w-6 h-6" />
            : <SystemThemeIcon class="w-6 h-6" />)}
      </button>
    </>
  )
}
